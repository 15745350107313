import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { AdminAPI } from "../../api-clients/AdminApi";

const SchoolAdmin = () => {
  const userInfo = useSelector((store) => store.auth.userInfo);
  const { schoolId } = userInfo;
  const [limits, setLimits] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const fetchLimits = async () => {
      setLoading(true);
      try {
        const response = await AdminAPI.getSchoolLimits({ schoolId });
        setLimits(response.data);
        const date = new Date(response.data.limitedDate);
        const today = new Date();
        const diffTime = Math.abs(date - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDaysLeft(diffDays);
      } catch (error) {
        setMessage("An error occurred while fetching limits.");
      } finally {
        setLoading(false);
      }
    };

    fetchLimits();
  }, []);

  const sendRequest = async (requestType) => {
    setLoading(true);
    try {
      const response = await AdminAPI.sendRequest({
        schoolId,
        requestType,
      });
      setMessage(
        `${response.data.message} A representative will contact you within 1-2 working days`
      );
    } catch (error) {
      setMessage(
        error.response
          ? error.response.data.message
          : "An error occurred while sending the request."
      );
      setMessage("An error occurred while sending the request.")
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="position-relative">
      <div style={{ opacity: loading ? 0.5 : 1 }}>
        <h2>Current Limits</h2>
        <p>Max Students: {limits.maxStudents || "N/A"}</p>
        <p>Current Student Count: {limits.currentStudentCount || "N/A"}</p>
        <p>
          Limited Date:{" "}
          {limits.limitedDate
            ? new Date(limits.limitedDate).toLocaleDateString()
            : "N/A"}
        </p>

        {daysLeft > 0 && (
          <p className="text-danger">
            Your subscription will expire in {daysLeft} days. Please renew it to
            avoid any disruptions.
          </p>
        )}
        <h4>Send Request</h4>
        <button
          className="btn btn-outline-info me-1"
          onClick={() => sendRequest("extend-students")}
        >
          Extend Student Limit
        </button>
        <button
          className="btn btn-outline-info"
          onClick={() => sendRequest("extend-date")}
        >
          Renew Subscription
        </button>

        {message && (
          <div className="alert alert-danger w-auto mt-2" role="alert">
            {message}
          </div>
        )}
      </div>

      {loading && (
        <div
          className="loading-indicator"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default SchoolAdmin;

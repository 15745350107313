import React, { use, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { T } from "@tolgee/react";
import { Card, CardBody } from "../../../components/card/card.jsx";
import VillageApi from "../../../api-clients/VillageApi.js";
import { useSelector } from "react-redux";
import { AdminAPI } from "../../../api-clients/AdminApi.js";
import {
  Button,
  message,
  Modal,
  notification,
  Table,
  Tooltip,
  Upload,
} from "antd";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ButtonGroup from "antd/es/button/button-group.js";
import UserApi from "../../../api-clients/UserApi.js";
import { SGPTAPI } from "../../../api-clients/SGPTApi.js";

// import { CSVLink } from "react-csv";

export default function StudentsPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const [modalError, setModalError] = useState("");

  const userInfo = useSelector((store) => store.auth.userInfo);
  const [allStudents, setAllStudents] = useState([]);
  const [addStudentLoading, setAddStudentLoading] = useState(false);

  const [addingStudents, setAddingStudents] = useState([
    {
      email: "",
      name: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [isCrtStudentAccModalOpen, setIsCrtStudentAccModalOpen] =
    useState(false);

  useEffect(() => {
    fetchTeacherData();
  }, []);

  const fetchTeacherData = async () => {
    try {
      setLoading(true);
      const classroomRes = await VillageApi.getClassroomsByTeacherId({
        teacher_id: userInfo.uid,
      });

      const studentRes = await AdminAPI.getStudent({
        schoolId: userInfo.schoolId,
      });
      setAllStudents(studentRes.data.rows);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (file) => {
    setLoading(true);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvData = event.target.result;
      const parsedData = csvData
        .split("\n")
        .slice(1)
        .map((row) => {
          const [name, email] = row.split(",");
          return { name: name, email: email };
        });

      setAddingStudents([...addingStudents, ...parsedData]);
    };

    reader.onerror = () => {
      notification.error({
        message: "Error",
        description: "Failed to read the file.",
      });
    };

    reader.readAsText(file);
    setLoading(false);
  };

  const handleRemoveStudent = async (record) => {
    try {
      const updatedStudents = allStudents.filter(
        (student) => student.id !== record.id
      );

      const body = {
        teacher_id: userInfo.uid,
        school_id: record.schoolId,
        students: updatedStudents,
      };

      await VillageApi.editClassroom(body);
      await AdminAPI.deleteUser({
        user_id: record.id,
      });
      setAllStudents(updatedStudents);
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Finished!");
    }
  };
  const columns = [
    {
      title: <T keyName="name" />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <T keyName="email" />,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <T keyName="id" />,
      dataIndex: "id",
      key: "id",
    },
    {
      title: <T keyName="status" />,
      dataIndex: "status",
      key: "status",
    },
    {
      title: <T keyName="action" />,
      key: "action",
      render: (text, record) => {
        return (
          <ButtonGroup style={{ columnGap: "2px" }}>
            <Button onClick={() => handleRemoveStudent(record)}>
              <T keyName="delete" />
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];
  const getStudentInfoByEmail = (email) => {
    const student = allStudents.find((student) => student.email === email);

    if (student) {
      return {
        student_id: student.id,
        name: student.name,
      };
    } else {
      return null; // Returns null if no student is found with the given email
    }
  };

  // email, name, password, schoolId, type, schoolType, schoolEmail, websiteUrl, trialDay
  const addStudentColumns = [
    {
      title: <T keyName="name" />,
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: <T keyName="email" />,
      dataIndex: "email",
      key: "email",
      editable: true,
    },
  ];
  const handleCrtStudentAccModalOK = async () => {
    setAddStudentLoading(true);
    const generatePassword = () => {
      const chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
      let password = "";
      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        password += chars[randomIndex];
      }
      return password;
    };
    const randomPassword = generatePassword();

    const validStudents = addingStudents.filter(
      (student) => student.name || student.email
    );

    validStudents.forEach((student) => {
      if (student.name.trim() === "" || student.email.trim() === "") {
        messageApi.error("Name and email cannot be empty.");
        return;
      }
    });

    const schoolData = await SGPTAPI.getSchoolData({
      schoolId: userInfo.schoolId,
    });

    await Promise.all(
      validStudents.map(async (student) => {
        const newUserDoc = {
          type: "Student",
          name: student.name,
          email: student.email,
          schoolId: userInfo.schoolId,
          password: 123456,
          schoolType: schoolData.data.type,
          schoolEmail: schoolData.data.email,
          websiteUrl: schoolData.data.websiteUrl,
          trialDay: 30,
        };
        try {
          setModalError("");
          const response = await UserApi.register(newUserDoc);
          fetchTeacherData();
          // Handle response if needed
          setIsCrtStudentAccModalOpen(false);
        } catch (error) {
          setModalError(error.response.data.message);
        } finally {
          setAddStudentLoading(false);
        }
      })
    );
  };
  return (
    <Card className="h-full">
      {contextHolder}
      <CardBody>
        <div className="flex items-center justify-between mb-4">
          <h2>
            <T keyName="all_students" />
          </h2>
          <div>
            <Button onClick={() => setIsCrtStudentAccModalOpen(true)}>
              Create Student Account
            </Button>
            {/* create a modal using antd: can upload csv or type, uploaded data shown on table, edit can type on table also for create student account */}
            <Modal
              title={<T keyName="Create Student Account" />}
              open={isCrtStudentAccModalOpen}
              afterClose={() => {
                setAddingStudents([{ name: "", email: "" }]);
              }}
              onOk={handleCrtStudentAccModalOK}
              onCancel={() => setIsCrtStudentAccModalOpen(false)}
              okText={<T keyName="submit" />}
              confirmLoading={addStudentLoading}
              cancelText={<T keyName="cancel" />}
            >
              <div className="mb-3">
                <label className="form-label">Upload CSV</label>
                <input
                  type="file"
                  className="form-control"
                  accept=".csv"
                  onChange={(e) => {
                    console.log("Uploading file:", e.target.files[0]);
                    handleUpload(e.target.files[0]);
                  }}
                />
              </div>

              {modalError && modalError !== "" && (
                <p className="text-danger">{modalError}</p>
              )}
              <table className="table table-hover">
                <thead>
                  <tr key="header_row">
                    {addStudentColumns.map((column, index) => (
                      <th key={`${column.key}_${index}`}>{column.title}</th>
                    ))}
                    <th key="action_column">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {addingStudents.map((student, index) => (
                    <tr key={student.id}>
                      {addStudentColumns.map((column) => (
                        <td key={column.key}>
                          {column.editable ? (
                            <input
                              type="text"
                              id={column.key}
                              key={column.key}
                              name={column.key}
                              value={student[column.dataIndex]}
                              className="form-control"
                              onChange={(e) => {
                                const updatedStudents = addingStudents.map(
                                  (item, i) =>
                                    i === index
                                      ? {
                                          ...item,
                                          [column.dataIndex]: e.target.value,
                                        }
                                      : item
                                );

                                if (
                                  updatedStudents.every(
                                    (item) =>
                                      item.name.trim() !== "" &&
                                      item.email.trim() !== ""
                                  )
                                ) {
                                  setAddingStudents([
                                    ...updatedStudents,
                                    { name: "", email: "" },
                                  ]);
                                } else {
                                  setAddingStudents(updatedStudents);
                                }
                              }}
                              onBlur={() => {
                                const uniqueStudents = addingStudents.filter(
                                  (item, i) =>
                                    i === index ||
                                    item.email !== student.email ||
                                    item.name !== student.name
                                );

                                setAddingStudents(uniqueStudents);
                              }}
                            />
                          ) : (
                            student[column.dataIndex]
                          )}
                        </td>
                      ))}

                      <td key="action" className="align-middle">
                        <div className="btn-group" role="group">
                          <Button
                            type="primary"
                            onClick={() => {
                              setAddingStudents(
                                addingStudents.filter(
                                  (item) => item.email !== student.email
                                )
                              );
                            }}
                          >
                            <T keyName="remove" />
                          </Button>
                          {student.hasOwnProperty("isExists") && (
                            <Tooltip
                              title={
                                student.isExists
                                  ? "Email already exists"
                                  : "Email is available"
                              }
                              placement="top"
                            >
                              <span
                                style={{ alignSelf: "center" }}
                                className={`ms-2 ${
                                  student.isExists
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {!student.isExists && (
                                  <i className="fas fa-check-circle text-success" />
                                )}
                                {student.isExists && (
                                  <i className="fas fa-times-circle text-danger" />
                                )}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={allStudents}
          loading={loading}
          rowKey={(record) => record.id}
        />
      </CardBody>
    </Card>
  );
}

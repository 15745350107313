import {
  Modal,
  Table,
  Button,
  Upload,
  Input,
  message,
  notification,
} from "antd";
import useLanguageToggle from "../../hooks/useLanguageToggle";
import { useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import BarsScale from "../../components/loading/BarsScale";
import ButtonGroup from "antd/es/button/button-group";
import { SGPTAPI } from "../../api-clients/SGPTApi";
import { useSelector } from "react-redux";
import UserApi from "../../api-clients/UserApi";

const AddTeacherModal = ({ isModalOpen, setIsModalOpen, handleRefresh }) => {
  const userInfo = useSelector((store) => store.auth.userInfo);
  const [modalError, setModalError] = useState("");
  const { translate } = useLanguageToggle();
  const [tableData, setTableData] = useState([{ name: "", email: "" }]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const inputRef = useRef(null);
  useEffect(() => {
    if (!isModalOpen) {
      setTableData([]);
      setFileList([]);
    }
  }, [isModalOpen]);

  const columns = [
    {
      title: translate("teacher-name"),
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Input
          value={record.name}
          onChange={(e) =>
            setTableData(
              tableData.map((item) =>
                item.key === record.key
                  ? { ...item, name: e.target.value }
                  : item
              )
            )
          }
          style={{ width: "200px" }}
        />
      ),
    },
    {
      title: translate("teacher-email"),
      dataIndex: "email",
      key: "email",
      render: (_, record) => (
        <Input
          value={record.email}
          onChange={(e) =>
            setTableData(
              tableData.map((item) =>
                item.key === record.key
                  ? { ...item, email: e.target.value }
                  : item
              )
            )
          }
          style={{ width: "200px" }}
        />
      ),
    },
    {
      title: translate("action"),
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          style={{ width: "100px" }}
          onClick={() =>
            setTableData(tableData.filter((item) => item.key !== record.key))
          }
        >
          {translate("delete")}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const hasEmptyEntry = tableData.filter(
      (item) => item.name?.trim() || item.email?.trim()
    );

    if (!hasEmptyEntry) {
      handleAdd();
    }
  }, [tableData]);

  const handleAdd = () => {
    setTableData([
      ...tableData,
      { key: `new-${tableData.length}`, name: "", email: "" },
    ]);
  };

  const handleUpload = (info) => {
    if (!info) return;
    setIsUploading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvData = event.target.result;
      const parsedData = csvData
        .split("\n")
        .slice(1)
        .map((row) => {
          const [name, email] = row.split(",");
          return { name: name, email: email };
        });
      console.log(parsedData);
      const tmpTableData = [...tableData, ...parsedData];
      console.log(tmpTableData);
      const filteredData = tmpTableData.filter(
        (item) => item.name?.trim() || item.email?.trim()
      );

      setTableData(filteredData);
    };
    reader.onerror = () => {
      // Failed to read the file, show an error notification.
      notification.error({
        message: "Error",
        description: "Failed to read the file.",
      });
    };
    reader.readAsText(info);
    setIsUploading(false);
  };

  const handleOnSubmit = async () => {
    const validStudents = tableData.filter(
      (student) => student.name || student.email
    );

    validStudents.forEach((student) => {
      if (student.name.trim() === "" || student.email.trim() === "") {
        message.error("Name and email cannot be empty.");
        return;
      }
    });

    const schoolData = await SGPTAPI.getSchoolData({
      schoolId: userInfo.schoolId,
    });
    console.log(schoolData);
    await Promise.all(
      validStudents.map(async (teacher) => {
        const newUserDoc = {
          type: "Teacher",
          name: teacher.name,
          email: teacher.email,
          schoolId: userInfo.schoolId,
          password: 123456,
          schoolType: schoolData.data.type,
          schoolEmail: schoolData.data.email,
          websiteUrl: schoolData.data.websiteUrl,
          trialDay: 90,
        };
        try {
          setModalError("");
          const response = await UserApi.register(newUserDoc);
          handleRefresh();
          // Handle response if needed
          setIsModalOpen(false);
        } catch (error) {
          setModalError(error.response.data.message);
        } finally {
          setIsUploading(false);
        }
      })
    );
  };
  const handleRemoveFile = () => {
    setFileList([]);
    inputRef.current.value = "";
  };

  return (
    <Modal
      title={translate("add-teacher")}
      open={isModalOpen}
      onOk={handleOnSubmit}
      onCancel={() => {
        setIsModalOpen(false);
        handleRemoveFile();
      }}
      okText={translate("submit")}
      cancelText={translate("cancel")}
      width={800}
    >
      <div className="mb-3">
        <input
          type="file"
          ref={inputRef}
          className="form-control"
          accept=".csv"
          onChange={(e) => {
            handleUpload(e.target.files[0]);
          }}
        />
      </div>
      <p className="text-danger">{modalError}</p>
      <Table
        columns={columns}
        dataSource={tableData}
        style={{ width: "100%" }}
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "" }}>
              <Button
                type="primary"
                style={{ marginRight: 8 }}
                onClick={handleAdd}
              >
                {translate("add")}
              </Button>
            </div>
          );
        }}
        pagination={{
          position: ["bottomRight"],
          showSizeChanger: false,
          showQuickJumper: false,
          showTotal: (total) => `Total: ${total} items`,
        }}
      />
    </Modal>
  );
};

export default AddTeacherModal;
